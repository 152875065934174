<template>
  <div class="home">
    <!-- <h2>欢迎您参加膝关节健康测评</h2> -->
    <h2>您好，欢迎您参加<span class="blue"> 小诺肌骨 </span>膝关节健康测评活动！</h2>
    <p>在本次活动中，由国家卫健委认证、国家队资深康复师为您的膝关节疼痛与不适情况，进行全面综合评估，包括体姿体态、功能动作质量、关节活动度、肌肉力量、膝关节结构检查等内容，会根据评估结果给出适合您的专属康复建议。此外，您将优先获得“智能居家康复设备”的免费体验！</p>
    <!-- <p class="blue bold">本次测评活动限时9.9元，超值体验，欲购从速！</p> -->
    <p> 温馨提示：本次活动仅适用于北京地区线下，针对膝关节疼痛的评估。1小时的评估+康复建议+智能设备体验，为您的膝关节健康保驾护航，快来参加吧！</p>
    <!-- <div class="disclaimerBox">
      <van-checkbox v-model="checked"></van-checkbox>
      <span>我已阅读并同意 <router-link to="/disclaimer" class="blue">免责条款</router-link></span>
    </div> -->
    <van-button :disabled="!checked" type="info" @click="next">报 名</van-button>
  </div>
</template>
<script>
import { scheduleStatus } from "@/api/getData"
export default {
  name: "Home",
  data() {
    return {
      checked: true
    }
  },
  created() {
  },
  mounted() {
    // if (!this.vToken) {
    //   if(!this.vImgUrl){
    //     this.toScheduleStatus()  
    //   }else{
    //     this.toweichat()
    //   }      
    // } else {
    //   this.toScheduleStatus()  
    // }
    this.getUserStatus()
  },
  methods: {
    getUserStatus(){
      if(this.vToken){
       this.toScheduleStatus()
      }else{
      this.$router.push('/login-sms')
      }
    },
    // GetParam(url, code) {
    //   url = url + "";
    //   let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
    //   let reg = eval(regstr);
    //   //eval可以将 regstr字符串转换为 正则表达式
    //   let result = url.match(reg);
    //   if (result && result[2]) {
    //     return result[2];
    //   }
    // },
    // toweichat() {
    //   //判断是否是微信浏览器
    //   let flag = navigator.userAgent.match(
    //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //   );
    //   {
    //     //判断是否是微信浏览器
    //     var ua = navigator.userAgent.toLowerCase();
    //     if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //       let code = this.GetParam(window.location.href, "code");
    //       if (code) {
    //         //这里可以进行后续操作 已经能出现code了 一般是调用接口发送给后台这个code码以换取openid或者token码
    //         authWechat(code).then(res => {
    //           var userInfo = {}
    //           userInfo.name = res.data.name
    //           userInfo.mobile = res.data.moblie
    //           userInfo.birthday = res.data.birthday
    //           userInfo.gender = res.data.gender
    //           userInfo.assessDate = res.data.assessDate
    //           userInfo.assessTime = res.data.assessTime
    //           // userInfo.wechatImgUrl = res.data.wechatImgUrl
    //           this.$store.commit('SET_vToken', res.data.token)
    //           this.$store.commit('SET_vUserInfo', userInfo)
    //           this.$store.commit('SET_vImgUrl', res.data.wechatImgUrl)      
    //           this.toScheduleStatus()        
    //         })
    //       } else {
    //         //获取当前页面的地址
    //         let local = `${process.env.VUE_APP_CLIENT_URL}`;//'http://wctest.ng.noitom.com.cn/#/';
    //         //调接微信官方生成授权登录的url appid必须填写
    //         //wx51177af5a0aaad8d
    //         let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + `${process.env.VUE_APP_WECHAR_APPID}` + "&redirect_uri="
    //           + encodeURIComponent(local)
    //           + "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
    //         //这一步是跳转这上方定义的url
    //         window.location.href = url;
    //       }
    //     } else {
    //       this.$toast.fail({
    //         message: '请使用微信浏览器',
    //         forbidClick: true,
    //         overlay: true,
    //         duration: 0
    //       })
    //     }
    //   }
    // },

    toScheduleStatus(){
      scheduleStatus().then(res => {
          // if (res.data) {
          //   this.$router.push({
          //     path: "/card",
          //     query: {
          //       scheduleId: res.data.schedules[0].scheduleId
          //     }
          //   })
          // }
          if ((res.data.schedules[0].status == 0 && res.data.schedules[0].paymentStatus == 2) || res.data.schedules[0].status == 1) {
            this.$router.push({
              path: "/card",
              query: {
                scheduleId: res.data.schedules[0].scheduleId
              }
            })
          } else if (res.data.schedules[0].status == 0 && res.data.schedules[0].paymentStatus == 1) {
            this.$router.push({
              path: "/wechatPay",
              query: {
                productCode: res.data.schedules[0].productCode,
                sn: res.data.schedules[0].sn,
                scheduleId: res.data.schedules[0].scheduleId
              }
            })
          } else if (res.data.schedules[0].status == 2 || res.data.schedules[0].status == 3 || res.data.schedules[0].status == 4) {
            // this.$router.push('/questionnaire')
          }
        })
    },

    // getStatus() {
    //   scheduleStatus().then(res => {
    //     console.log(res.data[0].status)
    //   })
    // },
    next() {
      this.$router.push('/questionnaire')
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding: 0.6rem 0.16rem 0;
  p {
    font-size: 0.18rem;
    line-height: 0.32rem;
    margin-top: 0.24rem;
  }
  .disclaimerBox {
    display: flex;
    margin-top: 1.8rem;
    font-size: 0.1rem;
    align-items: center;
    span {
      margin-left: 0.1rem;
    }
  }
  .van-button {
    margin-top: 0.5rem;
    height:0.46rem;
    border-radius:0.23rem
  }
}
</style>